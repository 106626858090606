import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import { authStore } from './store/auth';

const pinia = createPinia();

const app = createApp(App);

app.use(pinia);
await authStore().check();

app.use(router);
app.mount('#app');
