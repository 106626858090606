<template>
  <router-view/>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App'
});
</script>

<style>
.app {
  height: 100%;
  width: 100%;
}
</style>

<style lang="less">
.theme() {
  @import (multiple) './less/dialog.less';
  @import (multiple) './less/tile.less';
  @import (multiple) './less/tree.less';
}

.dark {
  @import '@eds/vanilla/variables/dark';
  .theme();
}

.light {
  @import '@eds/vanilla/variables/light';
  .theme();
}
</style>